export default function Skills() {
  return (
    <div>
      <span className="syntax s-skills" aria-hidden="true">
        &lt;section&gt;
      </span>

      <div className="skills--wrapper">
        <div>
          <span className="syntax s-skills-h2" aria-hidden="true">
            &lt;h2&gt;
          </span>
          <h2>Skills &amp; tools</h2>
          <span className="syntax s-skills-h2" aria-hidden="true">
            &lt;/h2&gt;
          </span>
        </div>

        <ul className="skills--list">
          <li>javascript es6+</li>
          <li>typescript</li>
          <li>react</li>
          <li>html</li>
          <li>css</li>
          <li>sass/scss</li>
          <li>styled components</li>
          <li>CSS-in-js</li>
          <li>jest</li>
          <li>react testing library</li>
          <li>git</li>
        </ul>
      </div>

      <span className="syntax s-skills" aria-hidden="true">
        &lt;/section&gt;
      </span>
    </div>
  );
}
