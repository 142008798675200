export default [
  {
    img: "https://i.imgur.com/FNfX9Wt.png",
    tag: "Profile search app",
    title: "GitHub User Search App",
    description:
      "An app that allows you to search for GitHub users with their usernames. Includes a theme toggle to select either light mode or dark mode. Built with React, React styled-components, and the GitHub users API.",
    github: "https://github.com/jenniferokafor/devfinder.git",
    website: "https://devfinder-search.netlify.app/",
  },
  {
    img: "https://i.imgur.com/qDI1AKB.png",
    tag: "Random generator",
    title: "Get Advice",
    description:
      "Get random advice from this app with the click of a button. Built using the Advice Slip JSON API, React, and React styled components.",
    github: "https://github.com/jenniferokafor/advice-app",
    website: "https://use-random-advice.netlify.app/",
  },
  {
    img: "https://i.imgur.com/wZjdzH3.png",
    tag: "Landing page",
    title: "Suite SaaS",
    description:
      "A single-page static website for a SaaS. Built with React and React styled-components.",
    github: "https://github.com/jenniferokafor/suite-landing-page",
    website: "https://use-suite.netlify.app/",
  },
];

// format
// {
//     img: '',
//     tag: '',
//     title: '',
//     description: '',
//     github: '#',
//     website: '#',
// }

//coffeeroasters
// {
//     img: 'https://i.imgur.com/ADwA1St.png',
//     tag: 'Coffee subscription app',
//     title: 'Coffeeroasters',
//     description: 'A 3-page website with an interactive susbscription page',
//     github: '#',
//     website: '#',
// },
