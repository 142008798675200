// importing svgs
import { ReactComponent as Github } from '../assets/svg/project-github.svg';
import { ReactComponent as Link } from '../assets/svg/project-link.svg';

export default function Projects (props) {

    return (
            
                <div className='project'>
                    <img src={props.item.img}/>
                    <div className='project--text'>
                        <h4>{props.item.tag}</h4>
                        <h3>{props.item.title}</h3>
                        <p>{props.item.description}</p>
                        <div className='project--links'>
                            <a href={props.item.github} target='_blank'><Github className='project--icon'/></a>
                            <a href={props.item.website} target='_blank'><Link className='project--icon'/></a>
                        </div>
                    </div>
                </div>
            
    )
}