import projectData from "./data/data";
import Projects from "./projects";

export default function ProjectContainer() {
  //
  const allProjects = projectData.map((item) => {
    return <Projects key={item.id} item={item} />;
  });

  return (
    <div>
      <span className="syntax s-projects" aria-hidden="true">
        &lt;section&gt;
      </span>
      <div className="project--container">
        <div>
          <span className="syntax s-projects-h1" aria-hidden="true">
            &lt;h2&gt;
          </span>
          <h2>Some projects I have built</h2>
          <span className="syntax s-projects-h1" aria-hidden="true">
            &lt;/h2&gt;
          </span>
        </div>

        <div className="project--wrapper">{allProjects}</div>
      </div>
      <span className="syntax s-projects" aria-hidden="true">
        &lt;/section&gt;
      </span>
    </div>
  );
}
