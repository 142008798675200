import { ReactComponent as GithubMain } from "../assets/svg/github-header.svg";
import { ReactComponent as LinkedIn } from "../assets/svg/linkedin.svg";
import { ReactComponent as Twitter } from "../assets/svg/twitter.svg";
import { ReactComponent as ReactLogo } from "../assets/svg/logo.svg";
import ClickBtn from "./button";

export default function Footer() {
  return (
    <div>
      <span className="syntax s-footer" aria-hidden="true">
        &lt;footer&gt;
      </span>

      <footer>
        <div>
          <span className="syntax s-footer-h2" aria-hidden="true">
            &lt;h2&gt;
          </span>
          <h2>Get in touch</h2>
          <span className="syntax s-footer-h2" aria-hidden="true">
            &lt;/h2&gt;
          </span>
        </div>

        <p>
          I'm always happy to connect with people building cool things and to
          hear about interesting projects and ideas. Whether you want to discuss
          a potential collaboration, ask for advice, or just say hi, you can
          click the button below to grab my email.
        </p>

        <div>
          <span className="syntax s-footer-btn" aria-hidden="true">
            &lt;button&gt;
          </span>
          <br />
          <ClickBtn />
          <br />
          <span className="syntax s-footer-btn" aria-hidden="true">
            &lt;/button&gt;
          </span>
        </div>

        <div className="social">
          <a href="https://github.com/jenniferokafor" target="_blank">
            <GithubMain />
          </a>
          <a href="https://www.linkedin.com/in/jenniferokafor/" target="_blank">
            <LinkedIn />
          </a>
          <a href="https://twitter.com/jenkf_" target="_blank">
            <Twitter />
          </a>
        </div>

        <div className="attribute">
          <p>
            Designed by{" "}
            <a href="https://twitter.com/iotukoya" target="_blank">
              iOtukoya
            </a>
            . Built by Jen with
          </p>
          <ReactLogo className="react--logo" />
        </div>
      </footer>

      <span className="syntax s-footer" aria-hidden="true">
        &lt;/footer&gt;
      </span>
      <span className="syntax s-footer-sub close-body" aria-hidden="true">
        &lt;/body&gt;
      </span>
      <span className="syntax s-footer-sub close-html" aria-hidden="true">
        &lt;/html&gt;
      </span>
    </div>
  );
}
