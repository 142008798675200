
// components
import Header from "./components/header";
import Hero from "./components/hero";
import Skills from "./components/skills";
import Footer from "./components/footer";
import ProjectContainer from '../src/components/projectContainer';

function App() {
  

  return (
    <>
      <Header />
      <Hero />
      <ProjectContainer />
      <Skills />
      <Footer />
    </>
  );
}

export default App;
