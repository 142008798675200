//import svgs
import { ReactComponent as GithubMain} from '../assets/svg/github-header.svg';
import { ReactComponent as LinkedIn} from '../assets/svg/linkedin.svg';
import { ReactComponent as Twitter} from '../assets/svg/twitter.svg';

export default function Header () {
    return (
        <header>

            {/* nav section */}
                <span className='syntax s-absolute s-header-html' aria-hidden='true'>&lt;html&gt;</span>
                <span className='syntax s-absolute s-header-body' aria-hidden='true'>&lt;body&gt;</span>

                <h2>jennakafor00@gmail.com</h2>
                <h1>Jen.</h1>
                <div className='social'>
                    <a href='https://github.com/jenniferokafor' target='_blank'><GithubMain /></a>
                    <a href='https://www.linkedin.com/in/jenniferokafor/' target='_blank'><LinkedIn /></a>
                    <a href='https://twitter.com/jenkf_' target='_blank'><Twitter /></a>
                </div>
        </header>
    )
}