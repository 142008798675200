import { ReactComponent as Down } from "../assets/svg/chevron-down.svg";
import Projects from "./projects";
import ClickBtn from "./button";

export default function Hero() {
  return (
    <div className="hero">
      <div>
        <span className="syntax s-hero" aria-hidden="true">
          &lt;h1&gt;
        </span>
        <h1>Hi, I'm Jen!</h1>
        <span className="syntax s-hero" aria-hidden="true">
          &lt;/h1&gt;
        </span>
      </div>

      <p>
        <span className="syntax s-hero-open-p" aria-hidden="true">
          &lt;p&gt;
        </span>
        <br />
        I’m a software developer focused on building websites and web apps that
        help businesses solve user problems. I currently build web apps at{" "}
        <span className="red">SunFi</span>, an energy fintech, where we're
        working to provide people with clean energy alternatives.
        <br />
        <br />
        In addition to my technical expertise, I also have experience in product
        management and content writing, which gives me a unique perspective on
        how to build products that people love as well as communicate these
        solutions to them.
        <br />
        <span className="syntax s-absolute s-hero-close-p" aria-hidden="true">
          &lt;/p&gt;
        </span>
      </p>
      {/* header CTAs */}
      <div>
        <span className="syntax s-hero" aria-hidden="true">
          &lt;button&gt;
        </span>

        <div className="header--cta">
          <ClickBtn />
          {/* <div className='view--work'>
                    <p>View my work</p>
                    <Down />
                </div> */}
        </div>

        <span className="syntax s-hero" aria-hidden="true">
          &lt;/button&gt;
        </span>
      </div>
    </div>
  );
}
