import React from 'react';

export default function ClickBtn () {

    const [text, setText] = React.useState('Send an email')

    // copies my email on click
    function clickCopy () {
        navigator.clipboard.writeText('jennakafor00@gmail.com');
        
        // replaces text on click
        setText('Email copied!');
        

        // returns button text after a second
        setTimeout(() => {
            setText("Send an email");
          }, 1000);
    }

    return (
        <button type='submit' className='btn-1' onClick={clickCopy}>
                    {text}
        </button>
    )
}
